<template>
  <b-card title="Avgifter" :sub-title="currentPeriod">
    <b-list-group v-if="filteredFees.length">
      <b-list-group-item
        v-for="(fee, index) in filteredFees"
        :key="index"
        class="d-flex justify-content-between">
        {{ feeDescription(fee) }}
        <b-button @click="deleteFee(fee.id)" size="sm" pill variant="danger">
          <font-awesome-icon :icon="['fas', 'trash-alt']" ></font-awesome-icon>
        </b-button>
      </b-list-group-item>
    </b-list-group>
    <em v-else>Inga avgifter är registrerade</em>

    <hr />

      <b-button
        id="fee-for-membership"
        @click="addMembershipFee"
        size="sm"
        class="mb-1">
        Medlem (200&nbsp;kr)
      </b-button>
      <b-button-group>
        <b-button
          id="fee-for-workout-adult"
          @click="addAdultWorkoutFee"
          size="sm">
          Vuxen (1000&nbsp;kr)
        </b-button>
        <b-button
          id="fee-for-workout-youth"
          @click="addYouthWorkoutFee"
          size="sm">
          Barn/Ungdom (700&nbsp;kr)
        </b-button>
        <b-button
          id="fee-for-workout-instructor"
          @click="addInstructorWorkoutFee"
          size="sm">
          Instruktör (550&nbsp;kr)
        </b-button>
        <b-button
          id="fee-for-workout-staff"
          @click="addStaffWorkoutFee"
          size="sm">
          Styrelse/kommmittéordf. (400&nbsp;kr)
        </b-button>
      </b-button-group>
      <b-button
        id="fee-for-overdue"
        @click="addOverdueFee"
        size="sm"
        class="mt-1">
        Förseningsavgift (100&nbsp;kr)
      </b-button>

      <template #footer>
        Summa: {{ totalFees }} kr
      </template>
    </b-card>
</template>

<script>
  export default {
    props: {
      currentPeriod: {
        type: String
      },
      showAll: {
        type: Boolean
      },
      fees: {
        type: Array,
        default: () => { return [] }
      }
    },
    data() {
      return {
        feeInfo: [
          { value: 'membership', amount: 200, text: "Medlemsavgift" },
          { value: 'workout-adult', amount: 1000, text: "Träningsavgift (vuxen)" },
          { value: 'workout-youth', amount: 700, text: "Träningsavgift (barn/ungdom)" },
          { value: 'workout-instructor', amount: 550, text: "Träningsavgift (instruktör)" },
          { value: 'workout-staff', amount: 400, text: "Träningsavgift (styrelse/kommittéordf)" },
          { value: 'overdue', amount: 100, text: "Förseningsavgift" },
        ]
      }
    },
    computed: {
      filteredFees: function () {
        return this.showAll
          ? this.fees
          : this.fees.filter(fee => fee.period === this.currentPeriod || fee.period === this.currentYear)
      },
      totalFees: function () {
        return this.totalAmount(this.filteredFees)
      },
      currentYear() {
        return this.currentPeriod.substring(3, 7)
      }
    },
    methods: {
      totalAmount(list) {
        const amounts = list.map((item) => item.amount)
        const total = amounts.reduce((sum, amount) => amount + sum, 0)
        return total
      },
      addMembershipFee() {
        const fee = { amount: 200, type: 'membership', period: this.currentPeriod, year: this.currentYear }
        this.addFee(fee)
      },
      addAdultWorkoutFee() {
        const fee = { amount: 1000, type: 'workout-adult', period: this.currentPeriod }
        this.addFee(fee)
      },
      addYouthWorkoutFee() {
        const fee = { amount: 700, type: 'workout-youth', period: this.currentPeriod }
        this.addFee(fee)
      },
      addInstructorWorkoutFee() {
        const fee = { amount: 550, type: 'workout-instructor', period: this.currentPeriod }
        this.addFee(fee)
      },
      addStaffWorkoutFee() {
        const fee = { amount: 400, type: 'workout-staff', period: this.currentPeriod }
        this.addFee(fee)
      },
      addOverdueFee() {
        const fee = { amount: 100, type: 'overdue', period: this.currentPeriod }
        this.addFee(fee)
      },
      addFee(fee) {
        this.$emit('addedFee', fee)
      },
      deleteFee(feeId) {
        this.$emit('deletedFee', feeId)
      },
      feeDescription(fee) {
        return `${this.feeTypeDescription(fee.type)} ${fee.period} ${fee.amount} kr`
      },
      feeTypeDescription(type) {
        let info = this.feeInfo.find(f => f.value == type)
        if (info && info.text) {
          return info.text
        } else {
          return type
        }
      }
    }
  }
</script>
