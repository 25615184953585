<template>
  <b-card title="Inbetalningar" :sub-title="currentPeriod">
    <b-list-group v-if="filteredPayments.length">
      <b-list-group-item
        v-for="(payment, index) in filteredPayments"
        :key="index"
        class="d-flex justify-content-between">
        {{ paymentDescription(payment) }}
        <b-button @click="deletePayment(payment.id)" size="sm" pill variant="danger">
          <font-awesome-icon :icon="['fas', 'trash-alt']"></font-awesome-icon>
        </b-button>
      </b-list-group-item>
    </b-list-group>
    <em v-else>Inga inbetalningar är registrerade</em>
    
    <hr />
    
    <b-form-group
        id="payment-group-method"
        label="Betalsätt:"
        label-for="payment-method"
        label-cols="4">
      <b-form-radio-group
	id="payment-method"
        v-model="payment.method"
        :options="paymentMethods"
      ></b-form-radio-group>
      </b-form-group>
      <b-form-group
        id="payment-group-date"
        label="Betaldatum:"
        label-for="payment-date"
        label-cols="4">
        <b-form-input id="payment-date" v-model="payment.date"/>
      </b-form-group>
      <b-form-group
        id="payment-group-amount"
        label="Inbetalt belopp:"
        label-for="payment-amount"
        label-cols="4">
        <b-form-input id="payment-amount" v-model.number="payment.amount"/>
      </b-form-group>
      <b-form-group
        id="payment-group-note"
        label="Anteckning:"
        label-for="payment-note"
        label-cols="4">
        <b-form-input id="payment-note" v-model="payment.note"/>
      </b-form-group>
      <b-button
        id="payment-save"
        @click="save"
        :disabled="payment.amount <= 0 || saving"
        variant="primary">
        <span v-if="saving"><b-spinner small></b-spinner>Sparar...</span>
	<span v-else>Spara</span>
      </b-button>

    <template #footer>
      Summa: {{ totalPayments }} kr
    </template>
  </b-card>
</template>

<script>
  import { todaysDate } from '@/utils/date.js'

  export default {
    props: {
      currentPeriod: {
        type: String
      },
      showAll: {
        type: Boolean
      },
      payments: {
        type: Array,
        default: () => { return [] }
      }
    },
    data() {
      return {
        payment: {
          date: todaysDate(),
          amount: '',
          method: 'bg',
          note: ''
        },
        saving: false,
        paymentMethods: [
          { text: 'Bankgiro', value: 'bg' },
          { text: 'Kort', value: 'card' },
          { text: 'Swish', value: 'swish' },
          { text: 'Kontant', value: 'cash' }
        ]
      }
    },
    computed: {
      filteredPayments: function() {
        return (this.payments && !this.showAll)
          ? this.payments.filter(payment => payment.period == this.currentPeriod)
          : this.payments
      },
      totalPayments: function () {
        return this.totalAmount(this.filteredPayments)
      },
      unusedPayment: function() {
        return this.totalPayments - this.totalFees
      }
    },
    methods: {
      totalAmount(list) {
        const amounts = list.map((item) => item.amount)
        const total = amounts.reduce((sum, amount) => amount + sum, 0)
        return total 
      },
      addPayment() {
        const payment = { amount: parseInt(this.amount, 10), date: this.date }
        this.amount = 0
        this.newPayments.push(payment)
      },
      deletePayment(paymentId) {
        this.$emit('deletedPayment', paymentId)
      },
      savePayment(payment) {
        this.$emit('addedPayment', payment)
      },
      paymentDescription(payment) {
        return `${payment.date} - ${payment.amount} kr`
      },
      save() {
        this.saving = true
        this.savePayment({ ...this.payment, period: this.currentPeriod })
        this.saving = false
      }
    }
  }
</script>
