<template>
  <div>
    <h1>Betalningar</h1>
    <b-row>
      <b-col>
        <b-button v-if="isProspect" @click="createMemberFromProspect" class="m-3">Skapa ny medlem från anmälan</b-button>
        <b-button v-b-modal.select-person-modal class="m-3">
          <span v-if="person">Byt person</span>
          <span v-else>Sök person</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="person" class="my-auto align-items-center">
          <MemberInfo :member="person" />
        </div>
      </b-col>
      <b-col>
        <ul v-if="person">
          <li>{{ person.personalIdNumber ? person.personalIdNumber : person.birthdate }}</li>
        </ul>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form inline class="m-3">
          <label>Aktiv period: </label>
          <b-form-select v-model="selectedPeriod" :options="periods" class="ml-2"/>
          <b-form-checkbox v-model="showAll" class="ml-2">Visa alla</b-form-checkbox>
        </b-form>
      </b-col>
    </b-row>
    <b-row v-if="person && !person.isProspect">
      <b-col>
        <AddPayment
          :currentPeriod="selectedPeriod"
          :showAll="showAll"
          :payments="payments"
          @addedPayment="addPayment"
          @deletedPayment="deletePayment"/>
      </b-col>
      <b-col>
	<AddFees
          :currentPeriod="selectedPeriod"
          :showAll="showAll"
          :fees="fees"
          @addedFee="addFee"
          @deletedFee="deleteFee"/>
      </b-col>
    </b-row>
    <SelectPersonModal id="select-person-modal" @person="setPerson" :allowChangingPeriod="true" />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import AddFees from '@/components/AddFees.vue'
  import AddPayment from '@/components/AddPayment.vue'
  import MemberInfo from '@/components/MemberInfo.vue'
  import SelectPersonModal from '@/components/SelectPersonModal.vue'

  export default {
    components: {AddFees, AddPayment, MemberInfo, SelectPersonModal},
    data() {
      return {
        periods: ['ht-2021', 'vt-2022', 'ht-2022', 'vt-2023', 'ht-2023', 'vt-2024', 'ht-2024', 'vt-2025'],
        selectedPeriod: 'ht-2024',
        showAll: false
      }
    },
    computed: {
      ...mapState('payments', ['fees', 'payments', 'person']),
      isProspect() {
        return this.person && this.person.isProspect
      },
      isMember() {
        return this.person && !this.person.isProspect
      }
    },
    methods: {
      ...mapActions('payments', ['addFee', 'createMemberFromProspect', 'deletePayment', 'addPayment', 'setPerson', 'bindPayments', 'bindFees', 'unbindPayments', 'unbindFees']),
      deleteFee() {
        alert('Not yet implemted');
      }
    },
    created() {
      if (this.person && !this.person.isProspect) {
        Promise.all([
          this.bindPayments(this.person.id),
          this.bindFees(this.person.id)])
        .then(() => {
          console.log('Payments and fees bound');
        }).catch((error) => {
          console.error('Failed to bind payments and/or fees:', error);
        });
      }
    },
    destroyed() {
      Promise.all([
        this.unbindPayments(),
        this.unbindFees()])
        .then(() => {
          console.log('Payments and fees unbound')
        })
        .catch((error) => {
          console.error('Failed to unbind payments and/or fees: ', error)
        })
    }
  }
</script>
